import React, { useEffect } from "react";
import "./app/landingpage/landingpage.css";
import { BrowserRouter } from "react-router-dom";
import HomePage from "./app/containers/homepage/homepage";
import "./i18n";
// import { ImageEngineProvider } from '@imageengine/react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TagManager from "react-gtm-module";

// require('dotenv').config();

const theme = createTheme({
  palette: {
    primary: {
      light: "#ff006a",
      main: "#ff006a",
      dark: "#ff006a",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

function App() {


  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <HomePage></HomePage>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
